import React from "react";

const ExperienceSection = () => {
  return (
    <>
    <div className="bgblack p-lg-5 p-4 d-flex justify-content-center  ">
      <div className="col-lg-9 col-12 align-items-center justify-content-center  experience_text  custom_padding ">
        <h1 className="textgrey m-lg-0 ">Experience</h1>
        <div className="d-flex gap-2 mb-lg-2 body_mind_text " >
          <h1 className="textgrey">True</h1>
          <h1 className="text-white">Mind-Body</h1>
          <h1 className="textgrey">Balance</h1>
        </div>
        <div className="d-flex gap-2 mb-lg-2 body_mind_text2 d-lg-none d-md-none d-block" >
          <p className="textgrey"> Experience True <span className="text-white">Mind-Body</span> Balance</p>
        </div>


        <div className="textgrey experience_desc col-lg-12 col-md-11 col-12 mt-lg-3 mt-2">
          <p className="">
            Immerse yourself in the transformative nature of mindfulness,
            experiencing serenity that takes over both body and mind. Our unique
            technology is not just a device but a pathway to discovering the
            most peaceful, whole, and fulfilling version of yourself.
          </p>
          <p>
            {" "}
            Elevate your concentration at work or while studying and foster
            emotional balance for a more serene lifestyle.
          </p>
        </div>
      </div>
    </div>


   
    </>
  );
};

export default ExperienceSection;

