import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
function RevolutionaryTech() {
  return (
    <>
      <div className=" main-container d-flex justify-content-center ">

        <div className="col-md-8 mb-5">
          <div className="mt-5 p-lg-0 p-3">
            <p className=" fs-1 world-class-text textgrey buttom">A world-class </p>
            <p className=" fs-1 text-white m-0">Revolutionary technology</p>
            <p className=" fs-2 world-class-text textgrey">
              That is a game changer in the fight against airborne diseases
            </p>
          </div>
          <div className="mt-lg-5 mt-2 ">
            <p className="certification_font d-flex world-class-text gap-2 mx-lg-0 mx-3 textgrey">
              <p className="text-white">Certifications  </p> and Acknowledgement
            </p>
            <div></div>
          </div>

          <div className="d-flex flex-lg-row flex-column certificate_img justify-content-center align-items-center">
            <div className="col-md-6 col-12">
              <img
                className="revo-image p-2"
                src="/productimage/recogition.jpeg"
              ></img>
              <img
                className="revo-image-2 p-2"
                src="/productimage/start-up-india.jpg"
              ></img>
            </div>

            <div className="col-md-6 col-12">
              <img
                className="revo-image p-2"
                src="/productimage/appriciation.png"
              ></img>
              <img
                className="revo-image-2 p-2"
                src="/productimage/nomination.jpeg"
              ></img>
              <img
                className="revo-image-2 p-2"
                src="/productimage/empower.jpeg"
              ></img>
            </div>
          </div>
          <div className=" text-lg-end text-center text_hover mt-lg-2">
         <h5> <Link to='/certifications' className="certificate_heading textgrey">  Check more Certificates 
          </Link></h5>
          </div>
        </div>


      </div>
    </>
  );
}

export default RevolutionaryTech;
