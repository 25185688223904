import React, { useContext, useState } from "react";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { Auth } from "../../Components/AuthContext/Authcontext";
import axios from "axios";
import { Link } from "react-router-dom";
function ProductInfo() {
  const {
    productData,
    AddToCart,
    longitude,
    latitude,
    getUserLocation,
    loadingProduct,
    addedCart,
  } = useContext(Auth);
  const [mainImage, setMainImage] = useState("/images/productimage-1.png");
  const listOfImage = [
    "/images/productimage-1.png",
    "/images/productimage-5.png",
    "/images/box.jpg",
    "/images/productimage-2.png",
    "/images/productimage-3.png",
    "/images/productimage-4.png ",
  ];
  const [quantity, setQuantity] = useState(1);
  const handleAddProduct = async (e, variant_id, qty) => {
    if (!latitude && !longitude) {
      getUserLocation(variant_id, qty);
    } else {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/store/regions`
      );
      const regions = response.data.regions;
      regions.map((item, i) => {
        item.countries.map((item, i) => {
          if (item.iso_2 === "in") {
            AddToCart(variant_id, qty, item.region_id);
          }
        });
      });
    }
  };
  return (
    <div className=" main-container p-0"
    //  style={{height:"40rem" , width:"100%"}}
    >
      <div
        className=" d-flex flex-lg-row flex-column "
        style={{ marginTop: "3.5%", }}
      >
        <div className="position-relative d-flex flex-lg-row  flex-column w-100">
          <div className=" w-100  col-lg-5 col-12 img-cont">
            <img
              className="product-image mt-3 "
              src={`${mainImage}`}
              alt="product image  "
              style={{ height: "550px", width: "100%", objectFit: "fill", overflow: "hidden" }}
            />
          </div>
          <div
            className=" product-image-multiple-container position-absolute d-flex gap-1 cursor overflow-scroll "
            style={{ marginLeft: "1%" }}
          >
            {listOfImage.map((item, i) => {
              return (
                <>
                  <img
                    onClick={() => {
                      setMainImage(item);
                    }}
                    className="product-image-multiple img-fluid "
                    src={`${item}`}
                    alt="product image"
                  />
                </>
              );
            })}
          </div>
        </div>
        <div style={{ height: '-1%' }} className="col-lg-7 col-12 product-cont p-lg-5 p-4 ">
          <div className="p-lg-5 mt-lg-3 p-4 p-md-5">
            <p className="text-black fs-2 buttom intrashieldcontainer ">
              Instashield - Health Meets Tomorrow
            </p>
            {/* <p className="sku-text">SKU:56659</p> */}
            <p className="w-100 ">
              A revolutionary electron based wellness technology that disables
              all  kinds of air born viruses, bacteria and fungi.
            </p>
            <div className="mt-4">
              <span>
                <FaStar className="text-warning" />
                <FaStar className="text-warning" />
                <FaStar className="text-warning" />
                <FaStar className="text-warning" />
                <CiStar className="text-warning" />
              </span>
              {productData[0]?.variants[0]?.prices[0]?.amount ? (
                <p className="text-black fs-1 buttom ">
                  {`₹${(productData[0]?.variants[0]?.prices[0]?.amount / 100) *
                    quantity
                    }`}
                </p>
              ) : (
                <div className="d-flex">
                  <div className="loader my-3"></div>
                </div>
              )}
              {/* <p className="text-black fs-1 buttom ">
                {`₹${(productData[0]?.variants[0]?.prices[0]?.amount / 100 * quantity)}`}
              </p> */}
              {/* <p className="text-black fs-1 buttom">
            ₹20,007
            </p> */}
              <p className="m-0 " style={{ fontSize: "14px" }}>
                (Exclusive of all taxes)
              </p>
              <p className="delivery-charge-price">
                Delivery Charges-India-Free | Other ₹4800/-
              </p>
            </div>
            <div className="d-flex gap-lg-2 gap-md-3 mt-5">
              <div className="d-flex align-items-center justify-content-center  update-price-container rounded ">
                <button
                  disabled={quantity <= 1 ? true : false}
                  onClick={() => {
                    setQuantity(quantity - 1);
                  }}
                  className="plus-qty fs-3 "
                >
                  -
                </button>
                <h6 className="plus-qty fs-5 m-0">{quantity}</h6>
                <button
                  onClick={() => {
                    setQuantity(quantity + 1);
                  }}
                  className="plus-qty fs-3 "
                >
                  +
                </button>
              </div>
              <div>
                <button
                  onClick={(e) => {
                    handleAddProduct(
                      e,
                      productData[0]?.variants[0].id,
                      quantity
                    );
                  }}
                  className="addtocart-container  text-white border-0 rounded"
                >
                  {loadingProduct ? "In progress..." : "Add To Cart"}
                </button>
              </div>
              <div className="d-flex align-items-center d-none d-lg-block d-md-block mt-2">
                <Link
                  className="buy-from-amazon_text"
                  to="https://www.amazon.in/Instashield-Wellness-Technology-Disabling-Bacteria/dp/B0CP7WDW2B/ref=sr_1_1?keywords=instashield&qid=1704692990&sr=8-1"
                  target="_blank"
                >
                  {" "}
                  Buy From Amazon <i class="fa-solid fa-share"></i>{" "}
                </Link>
              </div>

              {/* <p className="buy-from-amazon text-black fw-bold mx-2 ">or BUY FROM <Link to="https://www.amazon.in/Instashield-Wellness-Technology-Disabling-Bacteria/dp/B0CP7WDW2B/ref=sr_1_1?keywords=instashield&qid=1704692990&sr=8-1" target="_blank" className="intrashieldcontainer">AMAZON</Link></p> */}
            </div>
            <div className="d-flex d-lg-none col-md-5 d-md-none justify-content-center p-3">
              <Link
                className="buy-from-amazon_text"
                to="https://www.amazon.in/Instashield-Wellness-Technology-Disabling-Bacteria/dp/B0CP7WDW2B/ref=sr_1_1?keywords=instashield&qid=1704692990&sr=8-1"
                target="_blank"
              >
                {" "}
                Buy From Amazon <i class="fa-solid fa-share"></i>{" "}
              </Link>
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="d-flex flex-lg-row flex-column justify-content-center  mt-5 p-lg-5 p-0 w-100 ">
        {/* <div className="col-lg-9 d-flex align-items-center  flex-md-row flex-lg-row  flex-column justify-content-center ">
          <div className="col-lg-3 col-md-2   d-flex flex-column justify-content-center align-items-center ">
            <img className="four-image ." src="/productimage/safe.png"  ></img>
            <p className="text-white text-center buttom">100% SAFE</p>
          </div>
          <div className="col-lg-3 col-md-2 d-flex flex-column justify-content-center align-items-center">
            <img className="four-image " src="/productimage/plug.png"></img>
            <p className="text-white text-center buttom ">PLUG AND PLAY</p>
         
          </div>
          <div className="col-lg-3 col-md-2  d-flex flex-column justify-content-center align-items-center">
            <img className="four-image" src="/productimage/instant.png"></img>
            <p className="text-white text-center buttom">INSTANT</p>
          </div>
          <div className="col-lg-3 col-md-2   d-flex flex-column justify-content-center align-items-center">
            <img
              className="four-image"
              src="/productimage/effectiveness.png"
            ></img>
            <p className="text-white text-center buttom">EFFECTIVENESS</p>
          </div>
        </div> */}

        <div className="d-flex flex-row ">
          <div>
            <img className="w-100 " src="/productimage/safe.png"  ></img>
            <p className="text-white text-center ">100% SAFE</p>
          </div>
          <div>
            <img className="w-100 " src="/productimage/plug.png"  ></img>
            <p className="text-white text-center  ">PLUG AND PLAY</p>
          </div>
        </div>
        <div className="d-flex flex-row ">
          <div>
            <img className="w-100 " src="/productimage/instant.png"  ></img>
            <p className="text-white text-center ">INSTANT</p>
          </div>
          <div className="">
            <img className="w-100 " src="/productimage/effectiveness.png"  ></img>
            <p className="text-white text-center  ">EFFECTIVENESS</p>
          </div>
        </div>

      </div>
    </div>
  );
}
export default ProductInfo;
