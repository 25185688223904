import React from "react";
import "./index.css";

function Application() {
  return (
    <>
      <div className="main-container">
        {/* <p className="text-secondary"> Application Of</p> <b>Instashield</b> */}

        <p className="d-flex fs-5 application-text justify-content-center gap-2 ">
          <h3 className="text-secondary"> Applications of </h3>
          <h3 className="text-white ">Instashield</h3>
        </p>

        <div class="container-fluid p-3 mt-lg-2">
          <div class="row d-flex mt-5 flex-lg-row flex-column flex-md-row gap-md-5 justify-content-center align-items-center">

            <div class="col-md-2 col-sm-6 text-center mb-3">
              <img class="application-image zoom-in-zoom-out" src="/productimage/home-and-apparment.png" alt="Homes and Apartments"/>
                <p class="greytext mt-2">Homes and  Apartments</p>
            </div>
            <div class="col-md-2 col-sm-6 text-center mb-3">
              <img class="application-image zoom-in-zoom-out" src="/productimage/hotel.png" alt="Hotels and Restaurants"/>
                <p class="greytext mt-2">Hotels and <br/> Restaurants</p>
            </div>
            <div class="col-md-2 col-sm-6 text-center mb-3">
              <img class="application-image zoom-in-zoom-out" src="/productimage/hospital-and-clinic.png" alt="Hospitals and Clinics"/>
                <p class="greytext mt-2">Hospitals and <br/> Clinics</p>
            </div>
            <div class="col-md-2 col-sm-6 text-center mb-3">
              <img class="application-image zoom-in-zoom-out" src="/productimage/mall.png" alt="Malls and Function Halls"/>
                <p class="greytext mt-2">Malls and <br/> Function Halls</p>
            </div>

          </div>
          <div class="row d-flex flex-lg-row flex-column flex-md-row gap-md-5 justify-content-center align-items-center mt-lg-4">

            <div class="col-md-2 col-sm-6 text-center mb-3">
              <img class="application-image-2 mx-lg-0 mx-1 zoom-in-zoom-out" src="/productimage/school-and-college.png" alt="Schools and Colleges"/>
                <p class="greytext mt-2">Schools and <br/> Colleges</p>
            </div>
            <div class="col-md-2 col-sm-6 text-center mb-3">
              <img class="application-image-2 mx-lg-0 mx-1 zoom-in-zoom-out" src="/productimage/place-of-worship.png" alt="Places of Worship"/>
                <p class="greytext mt-2">Places of <br/> Worship</p>
            </div>
            <div class="col-md-2 col-sm-6 text-center mb-3">
              <img class="application-image-2 mx-lg-0 mx-1 zoom-in-zoom-out" src="/productimage/office.png" alt="Government Institutions"/>
                <p class="greytext mt-2">Government <br/> Institutions</p>
            </div>

          </div>
        </div>

      </div>
    </>
  );
}

export default Application;
