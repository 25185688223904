import React from "react";
import "./carousel.css";
import { BsArrowUpRightCircle } from "react-icons/bs";

const Carousel = () => {
  function changeContentBasedOnActiveSlide() {
    var slideOne = document.getElementById("one").className;
    var slideTwo = document.getElementById("two").className;
    // var slideThree = document.getElementById("three").className;
    var slideFour = document.getElementById("four").className;

    var one = document.getElementById("content1");
    var two = document.getElementById("content2");
   

    if (slideOne.includes("active")) {
      one.innerText = "Your shield";
      two.innerText = " against airborne diseases";
    } else if (slideTwo.includes("active")) {
      one.innerText = "Start Your ";
      two.innerText = "journey Towards Healthier & Happier You";
    } 
    // else if (slideThree.includes("active")) {
    //   one.innerText = "A World Class";
    //   two.innerText = " Revolutionary Wellness Device";

    // } 
    else if (slideFour.includes("active")) {
      one.innerText = "Your Ultimate";
      two.innerText = "Wellness Companion";
    }
  }
  return (
    <div>
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner ">
          <div
            className="carousel-item active"
            id="one"
            style={{ zIndex: "1" }}
          >
            <div className="d-none d-md-block ">
              <video
                className="carouselvid w-100 "
                autoPlay
                muted
                loop
                playsInline
              >
                <source src="images/carousel1.mp4" type="video/mp4" />
              </video>
            </div>

            <div className=" d-block d-sm-none w-100">
              <video className="w-100 " autoPlay muted loop playsInline>
                <source src="images/mobilevid.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="carousel-item " id="two" style={{ zIndex: "1" }}>
            <img
              className="d-none d-md-block w-100 img-fluid  carouselimg"
              src="/images/creataive01.png"
              alt="Second slide"
            />
            <img
              className="d-block d-sm-none w-100"
              src="/images/mobcarousel.jpg"
              alt="mobileview"
            />
          </div>

          {/* <div
            className="carousel-item d-flex align-items-center justify-content-center text_container"
            id="three"
          >
            <div className=" d-flex align-items-center justify-content-center  container w-75 ">
              <h1 className="textgrey2 carouseltext ">
                <span className="text-white">
                  Discover the future of wellness
                </span>
                <span>
                  {" "}
                  with Instashield, where breakthrough technology and innovation
                  unite to elevate your physical and mental well-being, ensuring
                  that you remain at the forefront of holistic living.
                </span>
              </h1>
            </div>
          </div> */}
          <div className="carousel-item " id="four" style={{ zIndex: "1" }}>
            <img
              className="d-none d-md-block w-100 img-fluid  carouselimg"
              src="/images/creataive.png"
              alt="Second slide"
            />
            <img
              className="d-block d-sm-none w-100"
              src="/images/mobcarousel1.jpg"
              alt="mobileview"
            />
          </div>
        </div>
        <div className="d-flex controls">
          <div className="carousel-content p-lg-4 p-3 d-flex ">
            <p className="m-0">
              <span id="content1" className="text-white ">
                Your shield
              </span>
              <span id="content2" className="mx-1 textgrey">
                {" "}
                against airborne diseases
              </span>
            </p>
          </div>

          <div
            className="card p-1 text-white next rounded-5 "
            href="#carouselExampleControls"
            role="button"
            data-bs-slide="next"
            onClick={() => changeContentBasedOnActiveSlide()}
          >
            <div className="position-absolute top-0 end-0 p-lg-2 p-1 ">
              <BsArrowUpRightCircle className="img-fluid icon1" />
            </div>
            <div className="position-absolute bottom-0 p-2">
              <p className="title m-0">Next</p>
              <p className="sub "> Health Meets Tomorrow</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
