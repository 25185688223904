import React, { useEffect, useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";

function Blog() {
  const [blogData, setBlogData] = useState(null);
  var blog1 = blogData?.data[0]?.attributes;
  var blog2 = blogData?.data[1]?.attributes;
  // console.log(blog1.slug, "blog 1")

  useEffect(() => {
    const fetchData = async () => {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/cms/api/blogs?sort[0]=id:desc&populate=*`,
          requestOptions
        );
        const result = await response.json();
        setBlogData(result);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="bgblack ">
      <Helmet>
        <meta
          name="title"
          content="Unlock Insights &amp; Inspiration: Explore Our Informative Blogs | Instashield"
        />
        <meta
          charSet="utf-8"
          name="description"
          content="Delve into a wealth of knowledge and inspiration on our wellness blog. Discover expert
insights, tips, and stories that empower you to embrace a healthier lifestyle. Explore the latest
trends in well-being and embark on a journey of self-discovery."
        />
        <title>
          Unlock Insights &amp; Inspiration: Explore Our Informative Blogs |
          Instashield
        </title>
        <link rel="cononical" href="https://www.instashield.org/blog" />
      </Helmet>

      <div className="container  ">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="col-lg-8 col-12 mt-5  p-lg-5  p-2 text-center ">
            <h2 className="textgrey mt-lg-0 mt-4 ">Explore</h2>
            <p className="textgrey headingfont">
              The World of <span className="text-white"> Instashield</span>
            </p>
            <p className="textgrey">
              A world-class revolutionary wellness device that creates a unique
              and healthy atmosphere for individuals who are seeking a better
              life. The patent wellness technology offers a range of benefits,
              including improved air quality, enhanced respiratory health, and a
              strengthened immune system.
            </p>
          </div>
        </div>

        {/* {
                    blogData?.data.map((item, i) => {
                                {console.log(item)}
                        if (i === 0) {
                            return (
                                <div className='mt-5  position-relative d-lg-block d-md-block d-none ' >
                                    <img src={`${process.env.REACT_APP_API_URL}/cms/${item?.attributes?.cardimage?.data?.attributes?.url}`} className='img-fluid' />

                                    <div className='col-lg-6 col-md-6 bg-white top-0   p-lg-5 p-4 d-flex flex-column justify-content-center align-items-center position-absolute'>
                                        <div className='p-lg-5 p-md-3 mt-lg-5 mb-lg-5'>
                                            <p className='textlightgray m-0' >{item?.attributes?.pdate}</p>
                                            <h4 className='textlightgray mt-1' >{item?.attributes?.Title}</h4>
                                            <ReactMarkdown children={`${(item?.attributes?.Content)?.slice(0, 280) }...`} />


                                        </div>
                                        <div className='righticon  w-100'>
                                            <Link to={`${item?.attributes?.slug}`} >
                                                <img src="/images/right_icon.svg" className='mx-lg-5 mx-3' />
                                            </Link>

                                        </div>
                                    </div>

                                </div>
                            )
                        } else {
                            return (
                              
                                <div className=' mt-5 d-flex flex-lg-row flex-column flex-md-row'>

                                    <div className='col-lg-6 col-md-6'>
                                        <img className='img-fluid w-100 ' src={`${process.env.REACT_APP_API_URL}/cms/${item?.attributes?.cardimage?.data?.attributes?.url}`} />
                                    </div>

                                    <div className='col-lg-6 col-md-6 bg-white d-flex flex-column justify-content-center align-items-center'>
                                        <div className='p-lg-5 p-4'>
                                            <p className='textlightgray m-0' >{item?.attributes?.pdate}</p>
                                            <h4 className='textlightgray mt-1' >{item?.attributes?.Title}</h4>
                                            <ReactMarkdown children={`${(item?.attributes?.Content)?.slice(0, 280) }...`} />

                                        </div>
                                        <div className='righticon w-100  ' >
                                            <Link to={`${item?.attributes?.slug}`}>
                                                <img src="/images/right_icon.svg" className='mx-lg-5 mx-3 ' />
                                            </Link>

                                        </div>
                                    </div>
                                </div>


                            )
                        }
                    })
                } */}

        {blogData?.data.map((item, i) => {
          return (
            <Link style={{textDecoration:"none",color:'black'}} to={`${item?.attributes?.slug}`  }>
            <div className=" mt-5 d-flex flex-lg-row flex-column flex-md-row">
              <div className="col-lg-6 col-md-6">
                <img
                  className="img-fluid w-100 "
                  src={`${process.env.REACT_APP_API_URL}/cms/${item?.attributes?.cardimage?.data?.attributes?.url}`}
                />
              </div>

              <div className="col-lg-6 col-md-6 bg-white d-flex flex-column justify-content-center align-items-center">
                <div className="p-lg-5 p-4">
                  <p className="textlightgray m-0">{item?.attributes?.pdate}</p>
                  <h4 className="textlightgray mt-1">
                    {item?.attributes?.Title}
                  </h4>
                  {/* <p className='textlightgray  mt-lg-5 mt-4'>{(item?.attributes?.Content)?.slice(0, 350)}...</p> */}
                  <ReactMarkdown
                    children={`${item?.attributes?.Content?.slice(0, 280)}...`}
                  />
                </div>
                <div className="righticon w-100  ">
                  <Link to={`${item?.attributes?.slug}`}>
                    {/* <i className="fa-solid fa-arrow-right-long fs-3 mx-lg-5 mx-3"></i> */}
                    <img
                      src="/images/right_icon.svg"
                      className="mx-lg-5 mx-3 "
                    />
                  </Link>
                </div>
              </div>
            </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Blog;
