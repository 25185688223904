import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Auth } from "../Components/AuthContext/Authcontext";

const Navbar = () => {
  const { addedCart } = useContext(Auth);

  const [isOpen, setIsOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);


  const divRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the clicked element is not within the div
      if (divRef.current && !divRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Add event listener on component mount
    document.addEventListener("mousedown", handleOutsideClick);

    // Remove event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos = window.scrollY;
  //     const isScrolledDown = prevScrollPos < currentScrollPos;

  //     setVisible(!isScrolledDown);
  //     setPrevScrollPos(currentScrollPos);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [prevScrollPos]);

  const handleMouseOver = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
    // window.scrollTo(0, 0);
  };

  const navlist = [
    { menu_name: "product", menu_link: "/product" },
    { menu_name: "technology", menu_link: "/technology" },
    { menu_name: "Blogs", menu_link: "/blog" },
    { menu_name: "enquiry", menu_link: "/enquiry" },
  ];

  const hoverMenu = [
    { menu_name: "home", menu_link: "/" },
    { menu_name: "product", menu_link: "/product" },
    { menu_name: "technology", menu_link: "/technology" },
    { menu_name: "certification", menu_link: "/certifications" },
    // { menu_name: "Dealership Enquiry", menu_link: "/enquiry" },
  ];

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const mobile_hover_menu = [
    { menu_name: "home", menu_link: "/" },
    { menu_name: "product", menu_link: "/product" },
    { menu_name: "technology", menu_link: "/technology" },
    { menu_name: "certification", menu_link: "/certifications" },
    { menu_name: "Blogs", menu_link: "/blog" },
    { menu_name: "contact us", menu_link: "/contact" },
    { menu_name: "About us", menu_link: "/about" },
    { menu_name: "Faqs", menu_link: "/faq" },
    { menu_name: "Dealership Enquiry", menu_link: "/enquiry" },
   

  ]

  return (
    <div>
      {/* <header
        className={` position-fixed top-0 w-100 ${
          visible ? "navbar-hidden" : "navbar-visible"
        }`}
        style={{ zIndex: "10000" }}
      >
        <nav className="navbar navbar-expand-lg  navbar-light">
          <div className="cart_count mx-lg-3 ">
            <div className="bg_green p-lg-3 p-2 nav_product d-flex flex-column text-center ">
              {isOpen ? (
                <img src="images/cross_icon.png"
                className="mt-2"
                  onClick={handleMouseLeave}
                ></img>
              ) : (
                <img src="images/nav_icon.png"
                  className=" mt-3 "
                  onClick={handleMouseOver}
                ></img>
              )}
              {addedCart?.cart?.items?.length !== undefined ? (
                <Link
                  to={`${addedCart?.cart?.items?.length !== 0 ? "/buy" : "#"}`}
                >
                  {" "}
                  <div className="position-relative">
                    <img
                      src="/images/product_icon.svg"
                      className="img-fluid mt-3 position-relative"
                      width={18}
                    />
                    <div className="count_icon">
                      <div className="count_div">
                        {addedCart?.cart?.items?.length}
                      </div>
                    </div>
                  </div>
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        </nav>
      </header> */}
      <header
        className={`bgblack position-fixed smooth top-0 w-100 ${
          visible ? "navbar-visible" : "navbar-hidden"
        }`}
        style={{ zIndex: "10000" }}
      >
        <nav className="navbar navbar-expand-lg  navbar-light">
          <div className="px-lg-5 px-3 p-lg-1 p-0">
            <Link
              className="navbar-brand flex-end"
              to="/"
              onClick={handleClick}
            >
              <img
                src="/images/updated_logo.svg"
                 style={{ width: "125px" }}
                className="img-fluid"
              />
            </Link>
          </div>
          <div className="collapse navbar-collapse  justify-content-end">
            <ul className="navbar-nav  navbar_list gap-lg-3 gap-1 mx-lg-5">
              {navlist?.map((item, index) => {
                return (
                  <li className="nav-item " key={index}>
                    <Link
                      className=" "
                      to={item.menu_link}
                      onClick={handleClick}
                    >
                      {item.menu_name}
                    </Link>
                   
                  </li>
                  
                );
              })}
            </ul>
          </div>

          <div className="cart_count mx-lg-3  ">
            <div  className="bg_green p-lg-3 p-2 nav_product d-flex flex-column text-center cursor ">
              {isOpen ? (
                <img src="images/cross_icon.png"
                className="cursor mt-2 "
                  onClick={handleMouseLeave}
                ></img>
              ) : (
                <img src="images/nav_icon.png"
                  className=" mt-3 cursor"
                  onMouseOver={()=> setIsOpen(!isOpen)}
                  onClick={handleMouseOver}
                ></img>
              )}
              {addedCart?.cart?.items?.length !== undefined ? (
              
                  <div className="position-relative">
                   <Link
                  to={`${addedCart?.cart?.items?.length !== 0 ? "/buy" : "#"}`}

                >  <img
                      src="/images/product_icon.svg"
                      className="img-fluid mt-3 position-relative"
                      width={18}
                    /></Link> 
                    <div className="count_icon">
                      <div className="count_div">
                        {addedCart?.cart?.items?.length}
                      </div>
                    </div>
                  </div>
               
              ) : (
                ""
              )}
            </div>
          </div>
        </nav>
        {isOpen ? (
          <div
            className="container-fluid bg1   position-absolute top-0 hover_test"
            // style={{ zIndex: 100 }}
            ref={divRef}
            onMouseLeave={handleMouseLeave} 
          >
            <div className=" hover_nav mt-lg-5 mt-2 mobile_hover_nav">
              <div className="row d-flex justify-content-center align-items- ">
                <div className="col-lg-7 col-12  p-lg-4 p-lg-3 p-3 ">
                  <Link
                    className="navbar-brand "
                    to="/"
                    onClick={handleMouseLeave}
                  >
                    <img
                      src="/images/new_logo.svg"
                      style={{ width: "140px", marginTop: "-3%" }}
                      className="img-fluid d-lg-block d-md-block d-none"
                    />
                    <img
                      src="/images/new_logo.svg"
                      style={{ width: "110px", marginTop: "-3%" }}
                      className="img-fluid d-lg-none d-md-none d-block mx-4"
                    />
                  </Link>

                  <div className="d-flex gap-lg-5 gap-4 mt-lg-4 mt-2 ">
                    <div>
                      <ul className="list-unstyled show2">
                        {hoverMenu?.map((item, index) => {
                          return (
                            <li
                              className="hovermenu_list mt-2 "
                              key={index}
                              onClick={handleMouseLeave}
                            >
                              <Link className=" " to={item.menu_link}>
                                {item.menu_name}
                              </Link>
                            </li>
                            
                            
                          );
                        })}
                        <li
                              className="hovermenu_list mt-2 "
                            
                              onClick={handleMouseLeave}
                            >
                              <Link className=" " to="/enquiry">
                                Dealership Enquiry
                              </Link>
                            </li>
                        
                      </ul>
                    </div>
                    <div className="mx-lg-5 show2">
                      <ul className="list-unstyled ">
                        <li className="hovermenu_list mt-2">
                          <Link
                            className="hovermenu_list"
                            to="/contact"
                            onClick={handleMouseLeave}
                          >
                            Contact us
                          </Link>
                        </li>
                        <li className="hovermenu_list mt-2">
                          <Link
                            className="hovermenu_list"
                            to="/about"
                            onClick={handleMouseLeave}
                          >
                            About us
                          </Link>
                        </li>
                        <li className="hovermenu_list mt-2">
                          <Link
                            className="hovermenu_list"
                            to="/blog"
                            onClick={handleMouseLeave}
                          >
                           Blogs
                          </Link>
                        </li>
                        <li className="hovermenu_list mt-2">
                          <Link
                            className="hovermenu_list"
                            to="/faq"
                            onClick={handleMouseLeave}
                          >
                           FAQs
                          </Link>
                        </li>
                        <li className="hovermenu_list mt-2">
                          <Link
                            className=""
                            target="_blank"
                            to="https://instashield-ecommerce-media.s3.ap-south-1.amazonaws.com/INSTASHIELD+BROCHURE+2023.pdf"
                          >
                            Download Brochure
                          </Link>
                        </li>
                        
                      </ul>
                    </div>


                  </div>
                  <div className="d-flex gap-lg-5 gap-4 mt-lg-5 mt-0 mx-4  ">
                  <div >
                      <ul className="list-unstyled show ">
                        {mobile_hover_menu?.map((item, index) => {
                          return (
                            <li
                              className="hovermenu_list mt-2 "
                              key={index}
                              onClick={handleMouseLeave}
                            >
                              <Link className=" " to={item.menu_link}>
                                {item.menu_name}
                              </Link>
                            </li>
                            
                            
                          );
                        })}
                       <li className="hovermenu_list mt-2">
                          <Link
                            className=""
                            target="_blank"
                            to="https://instashield-ecommerce-media.s3.ap-south-1.amazonaws.com/INSTASHIELD+BROCHURE+2023.pdf"
                          >
                            Download Brochure
                          </Link>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                  <div className="d-flex gap-lg-3 gap-2 hover_desc  mt-lg-2 mt-2 mb-lg-5 mx-lg-0 mx-3 show2">
                    <h1 className="textgrey">Health</h1>{" "}
                    <h1 className="text_blue">Meets Tomorrow</h1>
                  </div>
                  <div className="p-3 d-lg-none d-md-none d-block" style={{marginTop:"-3%"}}><hr className="text-white "></hr></div>

                  <div className="d-flex  gap-5 icons2 mx-4  d-lg-none d-md-none d-block " style={{marginTop:"-3%"}}>
          <Link to="https://www.facebook.com/instashield.ind/" target="_blank">
            <img src="/icons/fb.svg" alt="fb" />
          </Link>
          <Link
            to="https://www.instagram.com/instashield.ind/ "
            target="_blank"
          >
            {" "}
            <img src="/icons/insta.svg" alt="insta" />
          </Link>
          <Link
            to="https://www.youtube.com/channel/UCndtsfR9noPlHXukFY_OzeQ "
            target="_blank"
          >
            {" "}
            <img src="/icons/youtube.svg" alt="youtube" />
          </Link>
          <Link to="https://twitter.com/instashield_ind " target="_blank">
            {" "}
            <img src="/icons/twit.svg" alt="twit" />
          </Link>
          <Link
            to="https://www.linkedin.com/company/instashield-ind/ "
            target="_blank"
          >
            {" "}
            <img src="/icons/linkedin.svg" alt="linkedin" />
          </Link>
        </div>
                </div>
                <div className="col-lg-3 col-12 d-lg-block d-md-block d-none">
                  <img
                    src="/images/prod-02.png"
                    className="img-fluid  mt-lg-4" 
                    // width={220}
                  />
                </div>
              </div>
            </div>{" "}
          </div>
        ) : (
          ""
        )}
      </header>
    </div>
  );
};

export default Navbar;
