import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/style.css";

import "@fortawesome/fontawesome-free/css/all.min.css";
import Authcontext from "./Components/AuthContext/Authcontext";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
    <Authcontext>
      <App />
    </Authcontext>
    </BrowserRouter>
);

reportWebVitals();
