import React from "react";
import { Helmet } from "react-helmet";

const Aboutus = () => {
  return (
    <div className="bgblack justify-content-center align-items-center d-flex flex-lg-column flex-column p-lg-5 p-0">
       <Helmet>
       <meta name="title" content="Fight Against Airborne Diseases | Products Manufacture Keep Environment Virus Free" />
        <meta charSet="utf-8" name="description" content="Learn about the journey and values that drive Instashield India Private Limited. Explore
our commitment to revolutionizing well-being through innovation. Discover the people, passion, and
purpose that make us a trusted leader in the wellness industry."/>
        <title>Fight Against Airborne Diseases | Products Manufacture Keep Environment Virus Free</title>
        <link rel="cononical" href="https://www.instashield.org/about" />
      </Helmet>

      {/* <div className="col-lg-10 col-12 aboutus_div">
        <h2>Instashield India Private Limited</h2>
        <p>
          Instashield India Private Limited is a company registered in Hyderabad
          – Telangana, India as a private limited entity in August 2017, with
          its principal business activity as Manufacturing (Machinery &amp;
          Equipment). The company envisions to develop and deliver world-class
          health care technology products at reasonable prices for higher
          accessibility and affordability. The company’s Flagship product
          ‘Instashield’ is a Electron-based virus attenuation device with 99.9%
          efficacy against all kinds of viruses and bacteria that can penetrate
          through walls with activation in just 18 minutes and coverage of 5000
          sq ft.
        </p>
        <h2 >Vision</h2>
        <p>
          Manufacturing products that can keep the environment virus free and to
          make the latest technology in health care accessible to everyone.
        </p>
        <h2>Mission</h2>
        <p>
          Since the pandemic hit in 2020, the time people spend indoors has gone
          up exponentially. At Instashield, our mission is to ensure we make
          world class health care products with the latest technology available
          to make the immediate environment safe and virus free for people to go
          back to living their lives as normally as possible.
        </p>
      
        <h3>INNOVATION</h3>
        <p>
          An innovation to deliver world-class health care technology at
          reasonable prices for higher accessibility and affordability.
        </p>
        <h3>RELIABILITY</h3>
        <p>
          The world’s 1st proven technology that can disable all kinds of
          viruses and bacteria including the SARs-CoV2 and others with up to
          99.9% efficacy in enclosed space.
        </p>
        <h3>VIRUS FREE ATMOSPHERE</h3>
        <p>
          As the pandemic has ripped apart safety nets, people have been
          compelled to rethink hygiene. At Instashield, we are addressing this
          enormous need to keep people and their immediate environment safe and
          virus – free.
        </p>
        <h3>MAXIMUM PROTECTION</h3>
        <p>
          At Instashield we strive to provide the best in technology to create
          products that make people improve their quality of livelihood for
          better breathing.
        </p>

        <h4>
          Over 20 years of experience in Private Equity Placement. Hitesh has
          been involved in raising more than USD 50 Million by way of Private
          Equity Placement, IPO, Merger etc. Additionally, Hitesh plays a key
          role in developing macro investment themes, understanding strengths
          and weaknesses of specific companies, and identifying superior
          management teams. Post investment, he provides key strategic inputs on
          strengthening the business model of portfolio companies. He brings a
          unique perspective of understanding the value drivers across
          industries and at the same time has a First-hand understanding of the
          challenges faced by small to mid-size companies as they scale their
          businesses
        </h4>
      </div> */}

      <div className="container  textgrey align-items-center justify-content-center d-flex flex-column mt-lg-4 mt-5">
        <h5 className="mt-3">ABOUT COMPANY</h5>

        <h1 className="about_heading mt-3">Instashield India Private Limited</h1>
        <p className="width75 about_desc mt-3">
          Instashield India Private Limited is a company registered in Hyderabad
          – Telangana, India as a private limited entity in August 2017, with
          its principal business activity as Manufacturing (Machinery &amp;
          Equipment). The company envisions to develop and deliver world-class
          health care technology products at reasonable prices for higher
          accessibility and affordability. The company’s Flagship product
          ‘Instashield’ is a Electron-based virus attenuation device with 99.9%
          efficacy against all kinds of viruses and bacteria that can penetrate
          through walls with activation in just 18 minutes and coverage of 5000
          sq ft.
        </p>

        <div className=" mt-lg-5 mt-2  p-0 gap-lg-2 gap-4 container d-flex  row align-items-center justify-content-center mx-lg-0 mx-1 mb-lg-5">
          <div className=" col-12 card d-flex flex-column text-start card_design  p-lg-5 p-4">
            <h1>Vision</h1>
            <h5>
              Manufacturing products that can keep the environment virus free
              and to make the latest technology in health care accessible to
              everyone.
            </h5>
          </div>
          <div className=" col-12 card d-flex flex-column text-start card_design  p-lg-5 p-4">
            <h1>Mission</h1>
            <h5>
              Since the pandemic hit in 2020, the time people spend indoors has
              gone up exponentially. At Instashield, our mission is to ensure we
              make world class health care products with the latest technology
              available to make the immediate environment safe and virus free
              for people to go back to living their lives as normally as
              possible.
            </h5>
          </div>
        </div>

        <div className="col-lg-8 col-12 align-items-center   aboutus_text  mt-5 p-lg-0 p-2 mb-lg-5">
          <h1 className="textgrey">A stylish addition to elevate </h1>
          <div className="d-flex gap-lg-3 gap-2 ">
            <h1 className="text-grey">your</h1>
            <h1 className="text-white">living experience</h1>
          </div>

          <p className="mt-2 about_desc">
            A world-class revolutionary wellness device that creates a unique
            and healthy atmosphere for individuals who are seeking a better
            life. The patent wellness technology offers a range of benefits,
            including improved air quality, enhanced respiratory health, and a
            strengthened immune system.
          </p>

          <div className="mt-lg-5 mt-2">
            <img src="/images/about_us_group.png " className="img-fluid" />
          </div>
        </div>

        <h2 className="textgrey mt-5"> Body Mind Balance</h2>
        <h2 className="textgreen mt-3">HEALTH MEETS TOMORROW</h2>


        
      </div>
      <div className="col-lg-9 col-12 aboutus_div p-lg-0 p-3">
      <h2>INNOVATION</h2>
        <h5>
          An innovation to deliver world-class health care technology at
          reasonable prices for higher accessibility and affordability.
        </h5>
        <h2>RELIABILITY</h2>
        <h5>
          The world’s 1st proven technology that can disable all kinds of
          viruses and bacteria including the SARs-CoV2 and others with up to
          99.9% efficacy in enclosed space.
        </h5>
        <h2>VIRUS FREE ATMOSPHERE</h2>
        <h5>
          As the pandemic has ripped apart safety nets, people have been
          compelled to rethink hygiene. At Instashield, we are addressing this
          enormous need to keep people and their immediate environment safe and
          virus – free.
        </h5>
        <div className="col-12  py-lg-5 py-2 p-2">
              <img
                src="/images/about_us_img.png"
                alt="technology"
                className="techimg4 img-fluid w-100"
              />
            </div>
        <h2>MAXIMUM PROTECTION</h2>
        <h5>
          At Instashield we strive to provide the best in technology to create
          products that make people improve their quality of livelihood for
          better breathing.
        </h5>

      </div>

      
    </div>
  );
};

export default Aboutus;
