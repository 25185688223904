import React, { useContext, useEffect, useState } from "react";
import { Auth } from "../AuthContext/Authcontext";
import { Col, OverlayTrigger, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { Countryjson } from "../CountryJson";
import axios from "axios";
import './index.css'
import Loader from "./Loader";
const Basket = () => {
  const { addedCart, UpdateQuantity, setAddedCart, createAddress, localShipping, getShippingOption, ApplyGST,
    setCreateAddress, DeleteQuantity, getStateAndCity, gstValidated, setGstValidated, notify, notifyError, errorMessageForPincode, Paynow, loadingPayment, gst, setGst } = useContext(Auth)
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [checkout, setCheckout] = useState(false);

  const [IsValid, setIsValid] = useState(false);
  const SendPostalCode = (value) => {
    // setCreateAddress({ ...createAddress, postal_code:value })
    getStateAndCity(value);
  };
  // const handleAddGst = () => {
  //   setGst(e.target.value)

  // }
  function validateGST() {
    const gstRegex = /^[A-Z0-9]{15}$/;
    if (gstRegex.test(gst)) {
      notify('You GST number is valid')
      setGstValidated(true)
    } else {
      notifyError('You GST number is not valid')
      setGstValidated(false)

    }
  }
  const [deafultSelect, setDeafultSelect] = useState(false)
  const [checkCountry, setCheckCountry] = useState(false)
  const [region, setRegion] = useState("")
  const [regionList, setRegionList] = useState('')
  const [pinAndCityInIndia, setPinAndCityInIndia] = useState(false)
  const [pinAndCityOutIndia, setPinAndCityOutIndia] = useState(false)

  const handleMobileNumberValidation = (value) => {
    let mobileNumberPattern = /^\d{10}$/;
    if (mobileNumberPattern.test(value)) {
      setIsValid(false);
      setCreateAddress({ ...createAddress, phone: value });
      return true;
    } else {
      setIsValid(true);
      return false;
    }
  };
  const handlePincodeValidation = (value) => {
    let mobileNumberPattern = /^\d{10}$/;
    if (mobileNumberPattern.test(value)) {
      setIsValid(false);
      setCreateAddress({ ...createAddress, phone: value });
      return true;
    } else {
      setIsValid(true);
      return false;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    Paynow()
  }

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setCreateAddress({
      ...createAddress,
      email: e.target.value,
    })
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputEmail);
    setValidEmail(isValid);
  };
  const card_data = [
    {
      image: "images/icon1.svg",
      name: "Free Delivery",
    },
    {
      image: "images/icon2.svg",
      name: "Easy Return",
    },
    {
      image: "images/icon2.svg",
      name: "Warranty",
    },
    {
      image: "images/icon1.svg",
      name: "Secure Checkout",
    },
  ];
  const getRegions = () => {
    Countryjson.filter((item) => {
      if (item.name === region) {
        setRegionList(item)
      }
    })
  }
  const handleChangeRegion = (region) => {
    const new_cart_id = localStorage.getItem("cartid_instashield");
    setRegion(region)
    setDeafultSelect(true)
    Countryjson.filter(async (item, i) => {
      if (item.name === region) {
        const onslectIso = item.iso2
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/store/regions`);
        const regions = response.data.regions
        regions.map((item, i) => {
          item.countries.map(async (item, i) => {
            if (item.iso_2 === onslectIso.toLowerCase()) {
              setCreateAddress({
                ...createAddress,
                country_code: item.iso_2,
              })

              if (item.iso_2 === 'in') {
                setPinAndCityInIndia(true)
              } else {
                setPinAndCityInIndia(false)
              }

              // let data = JSON.stringify({
              //   "region_id": item.region_id
              // });
              // let config = {
              //   method: 'post',
              //   maxBodyLength: Infinity,
              //   url: `${process.env.REACT_APP_API_URL}/store/carts/${new_cart_id}`,
              //   headers: {
              //     'Content-Type': 'application/json',
              //   },
              //   data: data
              // };
              // axios.request(config)
              //   .then(async (response) => {
              //     console.log(response.data.cart, "carttttttttttttttt");
              //     if (response.status === 200 && response.data.cart) {
              //       setAddedCart(response.data.cart)
              //       getShippingOption(response.data.cart)
              //     }
              //     // console.log(JSON.stringify(response.data));
              //   })
              //   .catch((error) => {
              //     console.log(error);
              //   });
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              myHeaders.append("Accept", "application/json");

              var raw = JSON.stringify({
                "region_id": item.region_id
              });

              var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
              };

              fetch(`${process.env.REACT_APP_API_URL}/store/carts/${new_cart_id}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                  if (result) {
                    setCheckCountry(true)
                    setAddedCart(result)
                    getShippingOption()
                  }
                })
                .catch(error => console.log('error', error));

            }
          })
        })
      }
    })
  }

  useEffect(() => {
    getRegions()
  }, [region])
  return (
    <div className=" d-flex flex-lg-row flex-column">
      {
        checkout ?
          <div className="col-lg-8 col-12 p-lg-5 p-3 d-flex flex-column   ">
            <div className="mx-lg-5">
              <div className="col-lg-5 mt-3 d-flex gap-2">
                <IoMdArrowBack onClick={() => setCheckout(false)} size={25} className="mt-0" />
                <h5>Billing Address</h5>
                <hr className="m-0"></hr>
              </div>
              <form className="mt-l5 mt-3" onSubmit={(e) => { handleSubmit(e) }}>
                <div className="">
                  <Row className="d-flex justify-content-between ">
                    <Col lg={5} className="">
                      <input
                        onChange={(e) =>
                          setCreateAddress({
                            ...createAddress,
                            first_name: e.target.value,
                          })
                        }
                        type="input"
                        className="form_field_2 p-2"
                        placeholder="First name"
                        name="name"
                        id="name"
                        required
                      />
                    </Col>
                    <Col lg={5} className="mt-lg-0 mt-3">
                      <input
                        onChange={(e) =>
                          setCreateAddress({
                            ...createAddress,
                            last_name: e.target.value,
                          })
                        }
                        type="input"
                        className="form_field_2 p-2"
                        placeholder="Last name"
                        name="name"
                        id="name"
                        
                      />
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between mt-lg-4 mt-3">
                    <Col lg={5} className="">
                      <select onChange={(e) => { handleChangeRegion(e.target.value) }} className=" mb-3 form_field_2" aria-label=".form-select-lg example">
                        <option selected disabled>Select Country *</option>
                        {
                          Countryjson.map((item) => {
                            return (
                              <option value={item.name}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                    </Col>
                    <Col lg={5} className="mt-lg-0 mt-lg-2 mt-1">
                      <select className=" mb-3 form_field_2" aria-label=".form-select-lg example">
                        <option selected>Select State *</option>
                        {
                          regionList?.states?.map((item, i) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))
                        }
                      </select>
                    </Col>
                  </Row>
                  {
                    pinAndCityInIndia ?
                      <Row className="d-flex justify-content-between mt-2">
                        <Col lg={5} className="">
                          <input
                            onChange={(e) => SendPostalCode(e.target.value)}
                            type="input"
                            className="form_field_2 p-2"
                            placeholder="Pincode"
                            name="name"
                            required
                          />
                          {errorMessageForPincode ? (
                            <p className="text-danger text-xs italic mt-2">
                              Enter a Valid Pincode.
                            </p>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col lg={5} className="mt-lg-0 mt-3">
                          <input
                            value={createAddress.city}
                            type="input"
                            className="form_field_2 p-2"
                            placeholder="City"
                            name="name"
                            required
                          />
                        </Col>
                      </Row> :
                      <Row className="d-flex justify-content-between mt-2">
                        <Col lg={5} className="">
                          <input
                            onChange={(e) =>
                              setCreateAddress({
                                ...createAddress,
                                postal_code: e.target.value,
                              })
                            }
                            type="input"
                            className="form_field_2 p-2"
                            placeholder="Pincode"
                            name="name"
                            required
                          />

                        </Col>
                        <Col lg={5} className="mt-lg-0 mt-3">
                          <input
                            onChange={(e) =>
                              setCreateAddress({
                                ...createAddress,
                                city: e.target.value,
                              })
                            } type="input"
                            className="form_field_2 p-2"
                            placeholder="City"
                            name="name"
                            required
                          />
                        </Col>
                      </Row>
                  }

                  <Row className="d-flex justify-content-between mt-lg-4 mt-3">
                    <Col lg={5} className="">
                      <input
                        onChange={(e) =>
                          setCreateAddress({
                            ...createAddress,
                            address_1: e.target.value,
                          })
                        }
                        type="input"
                        className="form_field_2 p-2"
                        placeholder="Address 1"
                        name="name"
                        required
                      />
                    </Col>
                    <Col lg={5} className="mt-lg-0 mt-3">
                      <input
                        onChange={(e) =>
                          setCreateAddress({
                            ...createAddress,
                            address_2: e.target.value,
                          })
                        }
                        type="input"
                        className="form_field_2 p-2"
                        placeholder="Address 2"
                        name="name"

                      />
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between mt-4">
                    <Col lg={5} className="mt-lg-0 mt-3">
                      <input
                        value={createAddress.email}
                        onChange={handleEmailChange}
                        type="email"
                        className="form_field_2 p-2"
                        placeholder="Email"
                        name="email"
                        required
                      />
                      {/* {!validEmail ? (
                        <p className="text-danger text-xs italic mt-2">
                          Enter a Valid  Email.
                        </p>
                      ) : (
                        ""
                      )} */}
                    </Col>
                    <Col lg={5} className="">
                      <input
                        onChange={(e) =>
                          handleMobileNumberValidation(e.target.value)
                        }
                        type="input"
                        className="form_field_2 p-2"
                        placeholder="Phone"
                        name="name"
                        required
                      />
                      {IsValid ? (
                        <p className="text-danger text-xs italic mt-2">
                          Enter a Valid 10 digit Mobile Number.
                        </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
                <div>
                  <div className="mt-3">
                    {" "}
                    <input type="checkbox"></input>
                    <span className="mx-2 fontsize13">
                      I would Like to receive promotional communications based on
                      my preferences.
                    </span>
                  </div>
                  <div>
                    {" "}
                    <input checked type="checkbox"></input>
                    <span className="mx-2 fontsize13 m-0">
                      Your personal data will be used to process your order,
                      support your experience throughout this website, and for
                      other purposes described in our privacy policy.
                    </span>
                  </div>
                </div>
                <div className="mt-2">
                  <button disabled={checkCountry && !IsValid && createAddress.postal_code !== "" && createAddress.city !== "" && createAddress.first_name !== "" ? false : true} type="submit" className={`${checkCountry && !IsValid && createAddress.postal_code !== "" && createAddress.city !== "" && createAddress.first_name !== "" ? 'bgblack text-white wrapper' : 'bg-black text-black wrapper'} p-2 px-5 rounded`} >
                    {loadingPayment ? 'Please wait...' : 'Pay Now'}
                    {
                      checkCountry && !IsValid && createAddress.postal_code !== "" && createAddress.city !== "" && createAddress.first_name !== "" ? '' : <div className="tooltip">Please Fill All Details</div>
                    }

                  </button>

                  {/* <div className="wrapper">
                    I have a tooltip.

                    <div className="tooltip">I am a tooltip!</div>
                    
                  </div> */}

                </div>

              </form>
            </div>
          </div> :
          <div className="col-lg-8 col-12 p-lg-5 p-4 d-flex flex-column  justify-content-center ">
            <div className="mx-lg-5">
              <h3 className="mt-lg-0  mt-3">Your Basket</h3>
              <p className="textgrey fontsize13"> {addedCart?.cart?.items[0]?.quantity} item / Subtotal  {addedCart?.cart?.region?.countries?.length > 1 ? '$' : '₹'} {addedCart?.cart?.subtotal / 100}</p>
              {
                addedCart?.cart?.items && addedCart?.cart?.items?.length !== 0 ?
                  <>
                    {
                      addedCart?.cart?.items.map((item) => {
                        return (
                          <>
                            <div className="col-lg-9 col-12 mt-lg-5">
                              <hr className=""></hr>
                              <div className="d-flex justify-content-between align-itens-center">
                                <div className="col-lg-2">
                                  <img
                                    className="img-responsive p-2"
                                    src="images/Product.png "
                                    height="180"
                                  ></img>
                                </div>
                                <div className="col-lg-9 p-3">
                                  <h5 className="graish_text">{item.variant.title}</h5>
                                  <div className="d-flex justify-content-between mt-lg-4">
                                    <div className="col-lg-2 col-6 border d-flex justify-content-center align-items-center gap-2 " style={{ padding: "9px 13%" }}>
                                      <button disabled={item?.quantity <= 1 ? true : false}
                                        onClick={() => UpdateQuantity(item?.id, item?.quantity - 1)} className="fa-solid fa-minus fs-6 input-qty"></button> <span>{item?.quantity}</span>{" "}
                                      <button onClick={() => UpdateQuantity(item?.id, item?.quantity + 1)} className="fa-solid fa-plus fs-6 input-qty"></button>
                                    </div>
                                    <div className=" ">
                                      <img
                                        onClick={() => { DeleteQuantity(item?.id) }}
                                        src="images/delete_icon.png"
                                        className="img-fluid"
                                        width={17}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr className=""></hr>
                            </div>
                          </>
                        )
                      })
                    }
                  </>
                  : <div className="col-lg-7 rounded border d-flex p-lg-3 justify-content-between align-items-center mt-4">

                    <h3>Please Add Product </h3>
                    <Link to="/product" class="btn bg_green text-white">
                      Add Product
                    </Link>

                  </div>
              }


              <div className="mt-5">
                <h5 className=""> Why Buy InstaSheild?</h5>
                <div
                  className=" d-flex gap-3  mt-lg-3 row">
                  {card_data.map((item, index) => {
                    return <div className="col-lg-2 col-3 border text-center p-2 " key={index}>
                      <img src={item.image} className="img-fluid mt-3" />
                      <p className="fontsize13 mt-2">{item.name}</p>
                    </div>
                  })}
                </div>
              </div>
            </div>
          </div>
      }
      <div className="col-lg-4 p-lg-5 p-4 col-12 bglightgrey">
        <h3 className="mt-3">Order Summary</h3>

        <div className="textgrey col-lg-8 order_div mt-lg-3">
          <div className="d-flex justify-content-between ">
            <p className="graish_text">Subtotal</p>
            <p className="graish_text">{addedCart?.cart?.region?.countries?.length > 1 ? '$' : '₹'} {addedCart?.cart?.subtotal / 100}</p>

          </div>
          <div className="d-flex justify-content-between graish_text">
            <p >GST</p>
            <p >  {addedCart?.cart?.region?.countries?.length > 1 ? '$' : '₹'}  {addedCart?.cart?.tax_total / 100}</p> 
          </div>
          <div className="d-flex justify-content-between graish_text">
            <p >Shipping</p>
            {/* {addedCart?.cart?.shipping_total/100} */}
            {/* {console.log(localShipping)} */}
            
                {addedCart?.cart?.region?.countries?.length > 1 ? '$' : '₹'} {addedCart?.cart?.shipping_total === 0 ? localShipping === '' || localShipping === 0 ? 0 : localShipping?.amount / 100 : !deafultSelect ? addedCart?.cart?.shipping_total / 100 : localShipping?.amount / 100} 
           
          </div>

         
          <div className="d-flex justify-content-between graish_text">
            <p>Quantity</p>
            <p>{addedCart?.cart?.items[0]?.quantity}</p>
          </div>
        </div>
        <div className="col-lg-8 d-flex justify-content-between mt-3">
          <h3>Order Total</h3>
          <b className="flex items-baseline justify-end">
            
                {addedCart?.cart?.region?.countries?.length > 1 ? '$' : '₹'}  {
                  deafultSelect ? (
                    (
                      addedCart?.cart?.total / 100 +
                      (!localShipping ? 0 : localShipping?.amount / 100) -
                      (addedCart?.cart?.shipping_total / 100)
                    ).toFixed(2)
                  ) : addedCart?.cart?.total / 100
                }
             
            

            {/* {
              addedCart?.cart?.total / 100 
            } */}
            {/* {
              !deafultSelect ? addedCart?.cart?.total / 100 : ""
            } */}
            {/* ₹{addedCart?.cart?.total / 100} */}
            {/* {addedCart?.cart?.total / 100 +(!localShipping ? 0 : localShipping?.amount / 100)} */}
          </b>
        </div>
        <p className="graish_text">(inclusive of all taxes)</p>

        <div className="">
          <hr className="m-0"></hr>
          <div className="d-flex justify-content-between align-items-center ">
            <div className="  m-0">
              <input onChange={(e) => { setGst(e.target.value) }} className="inputbox-gst p-2 w-100" placeholder="Enter GST Number"></input>
            </div>
            <button onClick={validateGST} className="btn btn-primary px-4">Apply</button>
          </div>
          <hr className="m-0"></hr>
        </div>
        <div className="col-lg-10 mt-4">


          <div className="d-flex justify-content-between">

            <img src="images/payment.png" className="img-fluid" />
            {/* <img src="images/master_card.png" width={35} className="img-fluid" />
            <img src="images/paytm.png" width={35} className="img-fluid" />
            <img src="images/american.svg" className="img-fluid" />
            <img src="images/google-pay.png" width={35} className="img-fluid" />
            <img src="images/netbanking.png" width={32} className="img-fluid" />
            <img src="images/upi.png" width={32} className="img-fluid" /> */}

          </div>
          {/* <img src="images/avenure.png" width={90} className="img-fluid mt-2" /> */}
        </div>
        <div className="mt-4">
          {
            !checkout ? <div className="mt-4"> <button onClick={() => setCheckout(true)}
              className="checkout_btn"> CHECKOUT </button></div> : ""
          }
          <div className=" p-2  paymethod_div mt-5">
            <p className="m-0 fontsize13">Pay securely by Credit or Debit card or internet banking through CCAvenue Secure Servers.</p>
          </div>
        </div>

      </div>

    </div>




  );
};

export default Basket;
