import "./App.css";
import Navbar from "./Common/Navbar";
import Product from "./component/Product/Product";
import { Routes, Route } from "react-router-dom";
import Home from "./Home/Home";
import Footer from "./Common/Footer/Footer";
import { Technology } from "./Components/Technology/Technology";
import PageNotFound from "./PageNotFound";
import Enquiry from "./Components/Enquiry/Enquiry";
import Basket from "./Components/Basket/Basket";
import { useContext, useEffect } from "react";
import { Auth } from "./Components/AuthContext/Authcontext";
import { ToastContainer, toast } from "react-toastify";
import Thankyou from "./Components/Thankyou/Thankyou";
import Terms from "./Components/Terms/Terms";
import Contact from "./Components/Contact/Contact";
import Faq from "./Components/Faq/Faq";
import Test from "./Components/Test";
import Aboutus from "./Components/About/Aboutus";
import { Refund } from "./Components/Refund/Refund";
import Certificates from "./Components/Certificates/Certificates";
import Blog from "./Components/Blog/Blog";
import BlogPost from "./Components/Blog/BlogPost";


function App() {
  const { getProduct, AddedProduct } = useContext(Auth);
  useEffect(() => {
    AddedProduct();
    getProduct();
  }, []);
  return (
  
    <div className="">
      <Navbar />
      <div className="mt-5">
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggableste
          pauseOnHover
          theme="light"
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/buy" element={<Basket />} />
          <Route path="/product" element={<Product />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/test" element={<Test />} />
          <Route path="/about" element={<Aboutus />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/certifications" element={<Certificates />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />

        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
