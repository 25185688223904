import React, { useEffect, useRef } from "react";
import { Comprehensive } from "./Comprehensive";
import Carousel from "./Carousel/Carousel";

const AboutInstaShield = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video && video.paused) {
      video.play();
    }
  }, []);

  return (
    <>
      <div className=" overflow-hidden" style={{ backgroundColor: "black" }}>
        <div className="container position-relative ">
          <div className="d-lg-block d-md-block d-none">
            <video
              className="bg-video "
              autoPlay
              muted
              loop
              playsInline
              ref={videoRef}
            >
              <source src="images/1.mp4" type="video/mp4" />
            </video>

            <p className="video_text ">Health</p>

            <p className="text-white meet_text  ">meets tomorrow</p>
          </div>
          <div className="mt-4 " style={{ marginLeft: "-0px" }}>
            {" "}
            <video
              className="d-lg-none d-block p-3"
              autoPlay
              muted
              loop
              playsInline
              ref={videoRef}
              style={{ height: "600px" }}
            >
              <source src="images/mobile_video.MP4" type="video/mp4" />
            </video>
          </div>
        </div>


        <Comprehensive/>


        <div
            className=" d-flex align-items-center justify-content-center container  "
            // id="three"
          >
            <div className="col-lg-11 col-12 d-flex align-items-center justify-content-center   p-5 custom_padding">
              <h1 className="textgrey2 carouseltext ">
                <span className="text-white">
                  Discover the future of wellness
                </span>
                <span className="textgrey">
                  {" "}
                  with Instashield, where breakthrough technology and innovation
                  unite to elevate your physical and mental well-being, ensuring
                  that you remain at the forefront of holistic living.
                </span>
              </h1>
            </div>
          </div>


          <Carousel/>


        <div className=" d-flex flex-lg-row flex-column justify-content-center align-items-center gap-2  ">
          <div className="col-lg-7 p-lg-5 p-3 col-12  align-items-center d-flex flex-column justify-content-center   ">
            {/* <div className="d-flex mt-lg-0  gap-3 welcome_text mb-2 justify-content-center align-items-center ">
              <h1 className="text-white ">Welcome</h1>
              <h1 className="textgrey ">to Instashield!</h1>
            </div> */}

            <h2 className="textgrey text-center width75 display">
              Gateway to a future where every thought  is pure, every 
              breath serene, every moment peaceful.
            </h2>
            <p className="textgrey text-center mt-3 width75 display">
              Change the way you experience and manage your physical and mental
              wellbeing with advanced patented technology and holistic wellness
              principles that empower you on your journey to a healthier and
              happier life.
            </p>
          </div>
          <div className="col-lg-4 col-12 d-flex justify-content-center align-items-center mb-5 ">
            <div className="mt-lg-5 position-relative mb-lg-5 mb-2  display ">
              <img
                src="images/img1.png"
                className="img-fluid card_img card_animation "
                alt="First Image"
              />
              <img
                src="images/img2.png"
                className="img-fluid card_img2 card_animation"
                alt="Second Image"
              />
              {/* <img
                src="images/about_1.png"
                className="img-fluid card_img card_animation "
                alt="First Image"
              />
              <img
                src="images/about_2.png"
                className="img-fluid card_img2 card_animation"
                alt="Second Image"
              /> */}
            </div>
          </div>

          <div
            className="col-lg-7 p-lg-5 p-3 col-12  align-items-center d-flex flex-column justify-content-center  welcome_div d-lg-none d-md-none d-block "
            style={{ marginTop: "-5rem" }}
          >
            {/* <div className=" mb-2 justify-content-center  text-center welcome_mobile_text ">
              <h1 className="text-white ">Welcome</h1>
              <h1 className="textgrey ">to Instashield!</h1>
            </div> */}

            <h2 className="textgrey text-center width75 mt-lg-2 mt-5">
              Gateway to a future where every thought is pure,every breath
              serene, every moment peaceful.
            </h2>
            <div className="mt-4 position-relative mb-lg-5 mb-2   ">
              <img
                src="images/img1.png"
                className="img-fluid  card_img card_animation"
                alt="First Image"
              />
              <img
                src="images/img2.png"
                className="img-fluid card_img2 card_animation"
                alt="Second Image"
              />
            </div>
            <p className="textgrey text-center mt-3 width75 mb-lg-0 mb-5">
              Change the way you experience and manage your physical and mental
              wellbeing with advanced patented technology and holistic wellness
              principles that empower you on your journey to a healthier and
              happier life.
            </p>
          </div>
        </div>
        <div className="mt-3 d-lg-block d-none">
          <img src="images/group.png" className="img-fluid" />
        </div>

        <div className="mt-3 d-lg-none d-block">
          <img src="images/Group_mobile.png" className="img-fluid w-100" />
          <h2 className="textgrey p-4 mt-4">
            A future where well-being is not just about reacting to symptoms but
            proactively optimizing your health.
          </h2>
        </div>
      </div>
    </>
  );
};

export default AboutInstaShield;
