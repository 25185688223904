import React from "react";
import { TbExternalLink } from "react-icons/tb";
import { Link } from "react-router-dom";
import bgimage from "../../assets/img/for_katik_720.png"
import { Helmet } from "react-helmet";

export const Technology = () => {
  const uses = [
    {
      img: "/images/usesimg1.png",
      title: "MENTAL HEALTH",
      subtitle: "Symptoms of depression are reduced or eliminated",
    },
    {
      img: "/images/usesimg2.png",
      title: "IMMUNITY ANTIBACTERIAL SYSTEM",
      subtitle: "Strengthening the body's immunological system",
    },
    {
      img: "/images/usesimg3.png",
      title: "SLEEP PATTERN",
      subtitle: "Regulating one’s sleep pattern and emotional state",
    },
    {
      img: "/images/usesimg4.png",
      title: "METABOLISM",
      subtitle: "Boosting the metabolism",
    },
  ];
  const benefits = [
    {
      img: "/images/techicon1.png",
      title: "Kills all kind of air borne Viruses, Bacteria & Fungi",
    },
    { img: "/images/techicon2.png", title: "Non-toxic and Eco-friendly" },
    { img: "/images/techicon3.png", title: "Safe for your Family and Pets" },
    { img: "/images/techicon4.png", title: "Minimal Downtime" },
    { img: "/images/techicon5.png", title: "Rapid Response to Infected Areas" },
    { img: "/images/techicon6.png", title: "Maintenance Free" },
    { img: "/images/techicon7.png", title: "Low Energy Consumption" },
  ];
  return (
    <div>
       <Helmet  >
       <meta name="title" content="Revolutionary Wellness Technology | Stress Relief Product | Healthy Atmosphere" />
        <meta charSet="utf-8" name="description" content="Discover a revolutionary wellness device that transforms your environment, providing a
unique and healthy atmosphere for those aspiring to lead a better life."/>
        <title>Revolutionary Wellness Technology | Stress Relief Product | Healthy Atmosphere</title>
        <link rel="cononical" href="https://www.instashield.org/technology" />
      </Helmet>
    <div className="bg3">
      <section className="bgblack py-lg-5 py-5">
        <video className="tech-video" autoPlay muted loop playsInline>
          <source src="images/2.mp4" type="video/mp4" />
        </video>

        <p className="tech-video-text m-0 ">
          <span className="mt-5">TECHNOLOGY</span>
          <h2 className="text-white mb-5 mx-lg-0 mx-md-0 mx-5 Revolutionary_text">
            
            Revolutionary Wellness Technology
          </h2>
      </p>
      </section>
      <div className="container">
        <section className="px-lg-5 px-2 pt-lg-5 pt-3">
          <div className="px-l-5">
            <h1 className="textgrey m-0 p-lg-5 p-1  display-2">
              A stylish addition to elevate your
              <span className="text-white">  living experience.</span>
            </h1>
            <p className="textgrey m-0 py-2 px-lg-5 px-2 tech-p">
              A world-class revolutionary wellness device that creates a unique
              and healthy atmosphere for individuals who are seeking a better
              life. The patent wellness technology offers a range of benefits,
              including improved air quality, enhanced respiratory health, and a
              strengthened immune system. <br />
            </p>
            <p className="textgrey m-0 py-2 px-lg-5 px-2 tech-p">
              By bringing this remarkable innovation into your daily life, you
              can experience good living. Are you ready to elevate your living
              experience?
            </p>
          </div>
          <div className="py-5 mt-lg-5 mt-2  p-0 gap-lg-2 gap-4 container d-flex  row align-items-center justify-content-center mx-lg-0 mx-1">
            <div className=" col-12 card d-flex flex-column tech-card justify-content-center align-items-center p-lg-0 p-3">
              {/* <img
                src="images/blue_border.png"
                alt="Techimg"
                className="techimg1 p-lg-4 p-2  position-relative "
              /> */}
              <span className="tech-card-girl">
              <img
                src="images/tech_girl_img.png"
                alt="Techimg"
                className="zoom-in-zoom-out position-relative cursor girl_img p-1"
              />
              {/* <img
                src="images/tech_girl_img.png"
                alt="Techimg"
                className="techimg3 p-lg-4 p-2 zoom-in-zoom-out position-absolute cursor"
              /> */}
              </span>

              <h1 className="textgrey px-lg-5 px-2 py-3 health_font">
                Maintain health, balance energy, and improve well-being
              </h1>
            </div>
            <div className="col-lg-6 col-md-6 col-12 card tech-card d-flex justify-content-center align-items-center">
              <p className="textgrey p-lg-5 p-4 pendamic_font">
                The pandemic only adding the number of cases. With the recent
                pandemic, we all saw and understood how it does not take one
                virus outbreak to make the nation come to a standstill. It’s
                repercussions multi fold. This is when we decided to introduce a
                health and wellness device that would also work to disable
                viruses – InstaShield – The Negative Ion technology. Based
                purely out of science, and a proven backing from reputed
                organizations the entire working is based on the simple
                principle of NEGATIVE ION technology.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-12 tech-card">
              <div className="p-lg-5 p-3 textgrey">
                <h3>Can Electrons kill Viruses, Bacteria & Fungi?</h3>
                <p className="py-2">
                  Electrons can swiftly kill 99.9% of all sorts of viruses,
                  bacteria & fungi in the air, making the air safe and healthy
                  to breathe. Leveraging electron technology to fight the unseen
                  adversary of the 1400 pathogens that can infect humans, 500
                  can cause human-to human transmission.
                </p>
                <p>
                  Devices like Instashield that use electron technology prove an
                  effective and unprecedented shield against them.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12 tech-card position-relative d-flex justify-content-center">
              <img
                src="/images/Group_4792.png"
                alt="product"
                className="p-lg-5 p-4 img-fluid w-75"
              />
              {/* <img
                src="/images/bubble1.svg"
                alt="bubble1"
                className="bubble1"
              />
              <img
                src="/images/bubble2.svg"
                alt="bubble1"
                className="bubble2"
              />
              <img
                src="/images/bubble3.svg"
                alt="bubble1"
                className="bubble3"
              />
              <img
                src="/images/bubble4.svg"
                alt="bubble1"
                className="bubble4"
              /> */}
            </div>
          </div>
        </section>
        <section className="px-lg-5 px-0">
          <div className="textgrey m-0 px-lg-5 px-2 py-5 text-center">
            <h1 className="text-white electron_text">Are Electrons good for you?</h1>
            <p className="m-0 tech-p py-3 ">
              Serotonin levels in the brain are normalised by electrons, which
              may improve a person's outlook and mood, as well as mental
              concentration and performance. Electrons can significantly reduce
              the number of airborne viruses, bacteria & fungi hence decreasing
              the incidence of common colds, flu, hay fever, and even asthma. It
              helps de-stress by slowing your heart rate and lowering your blood
              pressure. Headaches and illness are less frequent when this
              product is switched on as it improves your quality of rest.
            </p>
          </div>
          <div className="row px-2">
            {uses.map((item) => (
              <div className="col-lg-3 px-5 px-lg-2 col-md-6 col-12 d-flex flex-column align-items-center text-center text-white">
                <img src={item.img} alt={item.title} className="techimg2" />
                <h5 className="textgrey">{item.title}</h5>
                <p className="tech-uses-p w-75 ">{item.subtitle}</p>
              </div>
            ))}
          </div>
        </section>
        <section className=" py-5  d-flex justify-content-center ">
          <div className="row benefits-card " >
            <div className="col-lg-8 col-12 pt-5 bg-black">
              <div className="ps-3 ">
                <h1 className="px-lg-5 px-2 py-2 pt-lg-5 pt-2 mt-2 text-white">
                  The benefit
                </h1>
                <h5 className="px-lg-5 px-2 textgrey">
                  of these negative ions for the human body includes
                </h5>
              </div>

              <div className="d-flex flex-wrap justify-content-center px-5 py-5">
                {benefits.map((item) => (
                  <div className=" d-flex  flex-column gap-3 align-items-center text-center benefits-content">
                    <img
                      src={item.img}
                      alt={item.title}
                      className="tech-card-icon"
                    />
                    <p className="textgrey">{item.title}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-4 col-12 m-0 p-0 position-relative Tech" >
              <img
                src="/images/benefitsimg.png"
                alt="benefits"
                className="img-fluid TechHoverImg"
              />
              <div className="tech-link text-white d-flex gap-1">
                <Link
                  className="product_link"
                  to="/product"
                  onClick={() => window.scroll(0, 0)}
                >
                  <p className="m-0">Check the Product</p>
                </Link>
                <TbExternalLink size={20} />
              </div>
            </div>
          </div>
        </section>
        <section className=" d-flex py-lg-5 py-4  justify-content-center ">
          <div className="row d-flex gap-5   justify-content-center  ">
            <div className="benefits-card py-5  d-flex  ">
              <div className="p-lg-5 px-3 py-1 ms-lg-5 textgrey">
                <h1 className="mx-lg-0 mx-4">
                  Why <span className="text-white">Instashield?</span>
                </h1>
                <h4 className="col-lg-12 lh-base">
                  {/* Best stress relief product for home and office<br></br> Helps
                  in controlling stress and anxiety<br></br> Reduces tension in
                  the office, and even boosts productivity<br></br> Instant
                  negative ion producer<br></br>
                  Helps you relax, sleep better, or think more clearly */}
                  <ul>
                    <li> Best stress relief product for home and office</li>
                    <li>Helps
                  in controlling stress and anxiety</li>
                    <li>Reduces tension in
                  the office, and even boosts productivity</li>
                    <li>Instant
                  negative ion producer</li>
                   <li>Helps you relax, sleep better, or think more clearly</li>
                    
                  </ul>
                </h4>
              </div>
            </div>
            <div className="col-12 d-flex py-lg-5 py-2  justify-content-center">
              <img
                src="/images/technology.png"
                alt="technology"
                className="techimg4 img-fluid"
              />
            </div>
          </div>
        </section>
        <section className=" py-5 px-lg-5  textgrey ">
          <div className="px-lg-5">
            <h1 className="textgrey m-0 px-lg-5 px-3 display-2">
              A World-class <br />
              <span className="text-white m-0"> revolutionary technology</span>
            </h1>
            <h2 className="display-6 p-lg-5 px-3 m-0 width75 textgrey mt-lg-0 mt-3">
              that is a game changer in the fight against airborne diseases
            </h2>
          </div>
        </section>
      </div>
    </div>
    </div>
  );
};
