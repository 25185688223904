// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bgblack {
    background-color: black;
}

.headingfont {
    font-size: 60px;
}

.textgrey {
    color: #929292;
}
.textlightgray{
    color: #5e5d5d;
}
.custombottom{
    bottom: 46.7%;
}
.righticon{
        display: flex;
            flex-flow: row-reverse;
}
.blog_font{
    font-size:18px;
  }
  .padding_btm{
    padding-bottom:6%
  }
@media only screen and (max-width: 640px) {
    .headingfont {
        font-size: 30px;
    }
        .custombottom {
            bottom: -5%;

        }
        .blog_font{
            font-size:16px;
          }
          .padding_btm{
            padding-bottom:12%
          }
}`, "",{"version":3,"sources":["webpack://./src/Components/Blog/index.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,aAAa;AACjB;AACA;QACQ,aAAa;YACT,sBAAsB;AAClC;AACA;IACI,cAAc;EAChB;EACA;IACE;EACF;AACF;IACI;QACI,eAAe;IACnB;QACI;YACI,WAAW;;QAEf;QACA;YACI,cAAc;UAChB;UACA;YACE;UACF;AACV","sourcesContent":[".bgblack {\n    background-color: black;\n}\n\n.headingfont {\n    font-size: 60px;\n}\n\n.textgrey {\n    color: #929292;\n}\n.textlightgray{\n    color: #5e5d5d;\n}\n.custombottom{\n    bottom: 46.7%;\n}\n.righticon{\n        display: flex;\n            flex-flow: row-reverse;\n}\n.blog_font{\n    font-size:18px;\n  }\n  .padding_btm{\n    padding-bottom:6%\n  }\n@media only screen and (max-width: 640px) {\n    .headingfont {\n        font-size: 30px;\n    }\n        .custombottom {\n            bottom: -5%;\n\n        }\n        .blog_font{\n            font-size:16px;\n          }\n          .padding_btm{\n            padding-bottom:12%\n          }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
