import React, { useContext, useEffect } from "react";
import AboutInstaShield from "../Components/Homepage/AboutInstaShield";
import Carousel from "../Components/Homepage/Carousel/Carousel";
import { Comprehensive } from "../Components/Homepage/Comprehensive";
import ExperienceSection from "../Components/Homepage/ExperienceSection";
import  { Auth } from "../Components/AuthContext/Authcontext";
import Media from "../Components/Homepage/Media";
import { Helmet } from "react-helmet";

const Home = () => {
  const { state ,getProduct} = useContext(Auth);
  useEffect(()=>{
    getProduct()
  },[])
  return (
    <div className="bg1">
       <Helmet>
        <meta name="title" content="Revolutionary Wellness Technology | Enhances Mental Wellbeing | Sterilized Environment" />
        <meta charSet="utf-8" name="description" content="Get Instashield wellness device for Physical and Mental health that takes care of your
body and mind. Experience a simple way to stay well and feel great. Order Now!"/>
        <title>Revolutionary Wellness Technology | Enhances Mental Wellbeing | Sterilized Environment</title>
        <link rel="cononical" href="https://www.instashield.org" />
      </Helmet>


      <AboutInstaShield />
     
      <ExperienceSection />
      {/* <Carousel /> */}
      <Media/>
    </div>
  );
};

export default Home;
