import React from "react";
import Table from "react-bootstrap/Table";
import "./index.css";

function ProductSpecification() {
  return (
    <>
      <div className="main-container p-3 d-flex justify-content-center ">
        <div className="col-md-8 col-12">
        <p className="d-flex fs-5 application-text mx-2 ">
                <p className="color-txt"> Product</p>
                <p className="text-white mx-2">Specification</p>
              </p>
          <div className="d-flex  flex-lg-row flex-md-row flex-column justify-content-center position-relative gap-lg-3 gap-3">
          
            <div className="col-md-8 col-12 border">
              
              <div className="col-lg-12">
                <hr className="text-white m-0  mb-3"></hr>
              </div>
              <p className="color-txt p-2">
                TECHNOLOGY : A revolutionary electron based wellness technology,
                that disables all kind of air borne viruses, bacteria and fungi.
              </p>
              <Table bordered responsive className="mt-2 table_div">
                <thead>
                  <tr>
                    <th>DIMENSIONS</th>
                    <th> Height- 30.05 CM, DIA- Top 155mm, Bottom- 190mm</th>
                  </tr>
                </thead>
                <tbody className="">
                  <tr>
                    <td>OPERATING INPUT VOLTAGE</td>
                    <td>Dc 9.00 volts/ 1 amp</td>
                  </tr>
                  <tr>
                    <td>COLOUR </td>
                    <td>Silver</td>
                  </tr>
                  <tr>
                    <td>BODY SHELL </td>
                    <td> ABS FR</td>
                  </tr>
                  <tr>
                    <td>DEVICE WEIGHT </td>
                    <td>1.3 KILOGRAM (APPROX) </td>
                  </tr>
                  <tr>
                    <td>INSTALLATION </td>
                    <td>Portable, Remote control</td>
                  </tr>
                  <tr>
                    <td>BODY SHELL </td>
                    <td> ABS FR</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="col-md-4 product-specifion p-4 d-flex flex-column justify-content-center align-items-center ">
              <div className="col-lg-8 col-12 ">
                <p className="fw-bold">
                  Instashield device is certified, verified and registered
                  partners with
                </p>
              </div>
              <img
                className="product-specifion-image "
                src="/productimage/productImage.png"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductSpecification;
