import React from "react";

const Terms = () => {
  return (
    <div className="bgblack m-0">
      <div className="container  py-5 textgrey ">
        <div className="px-lg-4 mx-lg-1 mx-0 mt-4" ><h1 className="m-0 px-lg-3 textgrey ">Terms and conditions</h1></div>
        <div className="px-lg-5 py-5">
          <h3 className="text-white">Terms:</h3>
          <p>
            By accessing the website at https://InstaShield.com, you are agreeing to
            be bound by these terms of service, all applicable laws and
            regulations, and agree that you are responsible for compliance with
            any applicable local laws. If you do not agree with any of these
            terms, you are prohibited from using or accessing this site. The
            materials contained in this website are protected by applicable
            copyright and trademark law.
          </p>
          <h3 className="text-white">Use License:</h3>
          <p>
            We are committed to conducting our business in accordance with these
            principles in order to ensure that the confidentiality of personal
            information is protected and maintained. InstaShield may change this
            privacy policy from time to time at InstaShield&#39;s sole discretion.
          </p>
          <h3 className="text-white">Modify or copy the materials:</h3>
          <p>
            use the materials for any commercial purpose; <br /> transfer the
            materials to another person or materials;
            <br /> attempt to decompile or reverse engineer any software;
            <br /> remove any copyright or other proprietary notations; <br />{" "}
            transfer the materials to another person or “mirror” the materials.
          </p>
          <h3 className="text-white">Disclaimer:</h3>
          <p>
            The materials on InstaShield’s website are provided on an ‘as is’ basis.
            InstaShield makes no warranties, expressed or implied, and hereby
            disclaims and negates all other warranties including, without
            limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, or non-infringement of
            intellectual property or other violation of rights.
          </p>
          <h3 className="text-white">Privacy Policy:</h3>
          <p>Your privacy is important to us.</p>
          <p>
            It is InstaShield’s policy to respect your privacy regarding any
            information we may collect while operating our website. Accordingly,
            we have developed this privacy policy in order for you to understand
            how we collect, use, communicate, disclose and otherwise make use of
            personal information. We have outlined our privacy policy below.
          </p>
          <ul>
            <li>
              We will collect personal information by lawful and fair means and,
              where appropriate, with the knowledge or consent of the individual
              concerned.
            </li>
            <li>
              Before or at the time of collecting personal information, we will
              identify the purposes for which information is being collected.
            </li>
            <li>
              We will collect and use personal information solely for fulfilling
              those purposes specified by us and for other ancillary purposes,
              unless we obtain the consent of the individual concerned or as
              required by law.
            </li>
            <li>
              Personal data should be relevant to the purposes for which it is
              to be used, and, to the extent necessary for those purposes,
              should be accurate, complete, and up-to-date.
            </li>
            <li>
              We will protect personal information by using reasonable security
              safeguards against loss or theft, as well as unauthorized access,
              disclosure, copying, use or modification.
            </li>
            <li>
              We will only retain personal information for as long as necessary
              for the fulfilment of those purposes.
            </li>
          </ul>
          <p>
            We are committed to conducting our business in accordance with these
            principles in order to ensure that the confidentiality of personal
            information is protected and maintained. InstaShield may change this
            privacy policy from time to time at InstaShield’s sole discretion.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
